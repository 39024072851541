<template>
	<div class="page">
		<el-tabs v-model="activeName" @tab-click="tabClick">
			<el-tab-pane label="推广数据" name="first">
				<Tables ref="xTable2" :tableData="List2" :totalPage="totalPage2" :isLoading="isLoading" :searchFormData="searchFormData2" :showRefresh="true" :showSearch="true" :showOutput="true" @getList="getList2" @clearSearch="clearSearch2">
					<!--搜索自定义内容-->
					<el-form-item slot="search-item">
						<el-date-picker v-model="searchFormData2.tm" size="small" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="注册开始日期" end-placeholder="注册结束日期">
    					</el-date-picker>
					</el-form-item>					
					<el-form-item slot="search-item">
						<!--el-select v-model="searchFormData2.user_id" size="small" placeholder="选择站长" clearable>
							<el-option v-for="(item) in userList" :value="item.user_id" :label="item.realname" :key="item.user_id"></el-option>
						</el-select-->
						<el-input v-model="searchFormData2.realname" size="small" placeholder="输入站长名" clearable></el-input>
					</el-form-item>
					<el-form-item slot="search-item">
						<el-select v-model="searchFormData2.status" size="small" placeholder="选择状态" clearable>
							<el-option v-for="(item,key) in userStatus" :value="key" :label="item" :key="key"></el-option>
						</el-select>
					</el-form-item>
					<div slot="count-info" class="countBox">
						<div>注册用户<br/><a>{{ registerNum }}</a></div>
						<div>激活用户<br/><a>{{ activeNum }}</a></div>
						<div>激活率<br/><a>{{ registerNum>0?(activeNum/registerNum*100).toFixed(2):0 }}%</a></div>
					</div>
					<!--表格内容-->
					<vxe-table-column slot="table-item" field="phone" title="手机号" />
					<vxe-table-column slot="table-item" field="fans_id" title="用户ID" />
					<vxe-table-column slot="table-item" field="cityText" title="归属城市" />
					<vxe-table-column slot="table-item" field="realname" title="推广站长" />
					<vxe-table-column slot="table-item" field="statusText" title="用户状态" />
					<vxe-table-column slot="table-item" field="chargeNum" title="激活后一个月内充电次数" />
					<vxe-table-column slot="table-item" field="activeTime" title="激活时间" />
					<vxe-table-column slot="table-item" field="register_time" title="注册时间" />
				</Tables>
			</el-tab-pane>
			<el-tab-pane label="推广设置" name="second">
				<Tables ref="xTable" :tableData="List" :totalPage="totalPage" :isLoading="isLoading"
					:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
					:showAdd="$hasAccess('market.app/add')" addName="新增" @getList="getList" @clearSearch="clearSearch"
					@addItemInTable="addOne">

					<!--搜索自定义内容-->
					<el-form-item slot="search-item">
						<el-input v-model="searchFormData.name" size="small" clearable placeholder="按名称查询">
						</el-input>
					</el-form-item>
					<!--表格内容-->
					<vxe-table-column slot="table-item" field="code" title="渠道号" />
					<vxe-table-column slot="table-item" field="realname" title="站长" />
					<vxe-table-column slot="table-item" field="ctime" title="创建时间" />
					<vxe-table-column slot="table-item" title="操作" width="300">
						<template v-slot="{ row }">
							<el-button size="small" plain @click="copyText(row)">复制下载链接</el-button>
							<el-button size="small" plain @click="edit(row)">编辑</el-button>
							<el-button size="small" plain @click="del(row)">删除</el-button>
						</template>
					</vxe-table-column>
				</Tables>

				<!-- 编辑 -->
				<el-dialog title="新增/编辑" :visible.sync="editDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="400px">
					<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">
						<el-form-item label="渠道号" prop="code">
							<el-input v-model="editDialogFormData.code" size="mini" @change="refreshCode"></el-input>
						</el-form-item>
						<el-form-item label="站长" prop="realname">
							<el-input v-model="editDialogFormData.realname" size="mini"></el-input>
						</el-form-item>
						<!--el-form-item label="站长" prop="user_id">
							<el-select v-model="editDialogFormData.user_id" size="mini">
								<el-option v-for="(item) in userList" :value="item.user_id" :label="item.realname" :key="item.user_id"></el-option>
							</el-select>
						</el-form-item-->
						<el-form-item label="下载链接" prop="url">
							<el-input v-model="editDialogFormData.url" size="mini"></el-input>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存
						</el-button>
						<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消</el-button>
					</div>
				</el-dialog>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script setup>
import { ref } from 'vue'
import Vue from 'vue'
import store from '@/store'
import API from '@/service/api.js'
import { MessageBox, Notification , Message} from 'element-ui'
import Tables from '@/components/tables'

const activeName = ref("first");
const userStatus = {"0":"待激活","1":"已激活","2":"激活失败"};

//卡片点击
function tabClick(){
	// if(activeName == "first"){
	// 	setTimeout(() => {
	// 		xTable2.value.refreshTable();
	// 	}, 500);
	// }else{
	// 	setTimeout(()=>{
	// 		xTable.value.refreshTable();
	// 	}, 500);
	// }
}

const isLoading = ref(false);
const totalPage = ref(0);
const searchFormData = ref({});
const List = ref([]);
// 表格列表
async function getList(currentPage, pageSize) {
	let params = {
		token: store.state.user.token,
		page: currentPage,
		size: pageSize
	}
	params = Object.assign(params, searchFormData.value);
	isLoading.value = true
	const res = await API.Market.appPublish.list(params);
	List.value = res.data;
	totalPage.value = res.total
	isLoading.value = false
	showRealName();

	setTimeout(()=>{
		let htmlnode = document.getElementsByClassName("vxe-table--body-wrapper");
		for(let nd of htmlnode){
			nd.style.height = 'auto';
		}
		htmlnode = document.getElementsByTagName("table");
		for(let nd of htmlnode){
			nd.style.width = '100%';
		}
	}, 200);
}

const totalPage2 = ref(0);
const searchFormData2 = ref({});
const List2 = ref([]);
const activeNum = ref(0);
const registerNum = ref(0);
const xTable2 = ref(null);
// 表格列表
async function getList2(currentPage, pageSize) {
	let params = {
		token: store.state.user.token,
		page: currentPage,
		size: pageSize
	}
	params = Object.assign(params, searchFormData2.value);
	isLoading.value = true
	const res = await API.Market.appPublish.users(params);
	List2.value = res.data;
	showRealName();
	registerNum.value = totalPage2.value = res.total
	activeNum.value = res.activeNum
	isLoading.value = false;

	setTimeout(()=>{
		let htmlnode = document.getElementsByClassName("vxe-table--body-wrapper");
		for(let nd of htmlnode){
			nd.style.height = 'auto';
		}
		htmlnode = document.getElementsByTagName("table");
		for(let nd of htmlnode){
			nd.style.width = '100%';
		}
	}, 200);
}

const userList = ref([]);
//获取站长列表
function getUsers(){
	API.System.UserMinList({token: store.state.user.token}).then((res)=>{
		userList.value = res;
		showRealName();
	});
}
getUsers();

//显示城市跟站长
function showRealName(){
	for(let v of List2.value){
		v.statusText = userStatus[v.status];
		for(let k in Vue.prototype.$userArea){
			if(v.city == k){
				v.cityText = Vue.prototype.$userArea[k];
				break;
			}
		}
		for(let c of List.value){
			if(v.from_channel == c.code){
				for(let u of userList.value){
					if(c.user_id == u.user_id){
						v.realname = u.realname;
						break;
					}
				}
				break;
			}
		}
	}
}

// 重置搜索
function clearSearch() {
	searchFormData.value = {}
}
// 重置搜索
function clearSearch2() {
	searchFormData2.value = {}
}

const editForm = ref(null);
const rules = ref({
	realname: [{
		required: true,
		message: '请输入名称',
		trigger: 'change'
	}],
	code: [{
		required: true,
		message: '请输入代码',
		trigger: 'change'
	}]
});
const editDialogShow = ref(false);
const editDialogFormData = ref({});
const xTable = ref(null);
// 重置form
function resetFormData() {
	editDialogFormData.value = { code: "" }
	if (editForm.value) {
		editForm.value.resetFields()
	}
}
// 新增
function addOne() {
	resetFormData()
	editDialogShow.value = true
}
// 编辑
async function edit(row) {
	resetFormData();
	editDialogFormData.value = Object.assign({}, row);
	delete editDialogFormData.value._XID;
	editDialogShow.value = true
}
// 删除
function del(row) {
	MessageBox.confirm('确定要删除该记录吗', '提示', {
		cancelButtonClass: 'btn-custom-cancel',
		confirmButtonText: '确定',
		cancelButtonText: '取消',
		type: 'warning'
	}).then(async () => {
		const params = {
			token: store.state.user.token,
			id: row.id
		}
		await API.Market.appPublish.del(params)
		Notification({
			title: '成功',
			message: '删除成功',
			type: 'success'
		})
		xTable.value.refreshTable();
	})
}
// 关闭弹窗
function closeDialog() {
	editDialogShow.value = false;
}
// 编辑保存
function saveEdit() {
	editForm.value.validate(async valid => {
		if (valid) {
			let params = Object.assign({ token: store.state.user.token }, editDialogFormData.value);
			if (editDialogFormData.value.id > 0) {
				await API.Market.appPublish.edit(params);
			} else {
				await API.Market.appPublish.add(params);
			}
			Notification({
				title: '成功',
				message: '操作成功',
				type: 'success'
			});
			closeDialog();
			xTable.value.refreshTable();
		}
	})
}
// 刷新code码
function refreshCode() {
	// let cd = ((Math.random() * 100000) | 0).toString().split('');
	// for (let i = 0; i < 3; i++) {
	// 	cd[(Math.random() * 6) | 0] = String.fromCharCode(((Math.random() * 26) | 0) + 65);
	// }
	// editDialogFormData.value.code = cd.join("").padEnd(6, 0);

	API.Market.appPublish.list({
		token: store.state.user.token,
		page: 1,
		size: 10,
		code: editDialogFormData.value.code
	}).then((res) => {
		if (res.total > 0 && res.data[0].id != editDialogFormData.value.id){
			//editDialogFormData.value.code = "";
			Notification({
				title: '警告',
				message: '渠道号已经存在',
				type: 'warning'
			});
		} 
	});
}

//复制文本
function copyText(row){
	navigator.clipboard.writeText(row.url);
	Message({message: '复制成功'});
}
</script>

<style lang="scss" scoped>
.el-tab-pane {
	min-height: 300px;
}
.countBox{
	background-color: #fff;
	margin-top: 8px;
	display: flex;
	padding: 20px 80px;
	div{
		margin-right: 160px;
	}
	a{
		font-size: 24px;
		font-weight: bold;
	}
}
</style>
